import React from "react";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

export const PhoneInput = ({
  customDesign = true,
  label,
  labelHeader,
  placeholder,
  control,
  rules,
  name,
  className,
  disabled,
  onFocus,
  onBlur,
}) => {
  return (
    <div
      className={`input-main-wrapper ${
        customDesign ? "input-custom-wrapper" : ""
      } ${className ? className : ""}`}
    >
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
        }) => {
          return (
            <>
              {labelHeader && (
                <p
                  className={`input-main-label-header ${
                    error?.message ? "errorLabel" : ""
                  }`}
                >
                  {labelHeader}
                  {rules?.required && (
                    <span className="input-main-label-header-required">*</span>
                  )}
                </p>
              )}
              <InputMask
                mask="(999) 999-9999"
                value={value}
                onChange={onChange}
                disabled={disabled}
                onFocus={onFocus}
                onBlur={onBlur}
              >
                {() => (
                  <TextField
                    error={!!error?.message}
                    name={name}
                    autoComplete="off"
                    placeholder={placeholder}
                    label={label}
                    disabled={disabled}
                  />
                )}
              </InputMask>

              <p className="error-message">
                {error?.type === "required"
                  ? "This Field is required"
                  : error?.message}
              </p>
            </>
          );
        }}
      />
    </div>
  );
};
