import React, { useState, Fragment, useContext, createContext } from "react";
import { Fade, Modal } from "@mui/material";
import { Svg } from "assets";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [params, setParams] = useState({});
  const [modalInfo, setModalInfo] = useState({});

  return (
    <Fragment>
      <ModalContext.Provider
        value={{
          params,
          setParams,
          modalInfo,
          setModalInfo,
        }}
      >
        {children}
      </ModalContext.Provider>
    </Fragment>
  );
};

export const useModal = () => {
  const { params, setParams, modalInfo, setModalInfo } = useContext(
    ModalContext
  );

  return {
    openModal: (params = {}, modalInfo) => {
      setParams(params);
      setModalInfo(modalInfo);
    },
    close: () => {
      setParams({});
      setModalInfo({});
    },
    params,
    modalInfo,
  };
};

export const Modals = () => {
  const { params, close, modalInfo } = useModal();
  const renderOpen = Object.keys(params).length !== 0;

  return (
    <div>
      {renderOpen && (
        <Modal
          style={{ zIndex: "1405" }}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={renderOpen}
          onClose={() => close()}
          closeAfterTransition
          hideBackdrop={false}
        >
          <Fade in={renderOpen}>
            <div
              style={
                modalInfo?.padding === "noPaddings"
                  ? { padding: 0, outline: "none" }
                  : { outline: "none" }
              }
              className={`modal-wrapper ${
                modalInfo?.className ? modalInfo?.className : ""
              }`}
            >
              {!modalInfo?.noShowCloseBrn && (
                <div className="modal-header-style">
                  <button
                    type="button"
                    className="close-modal-btn"
                    onClick={() => close()}
                  >
                    <Svg.Close />
                  </button>
                </div>
              )}
              <div className="modal-body-style">{params}</div>
            </div>
          </Fade>
        </Modal>
      )}
    </div>
  );
};
