import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { InputMain, ModalActions } from "components";
import { Svg } from "assets";
import {
  authActions,
  httpRequestsOnErrorsActions,
  httpRequestsOnSuccessActions,
} from "store";
import { ACCOUNT_ACTION_TYPE, PROFILE_ACTION_TYPE } from "./constants";
import { FindError, FindSuccess, Notify } from "utils";

export const Account = () => {
  const [showActions, setShowActions] = useState(false);
  const dispatch = useDispatch();
  const { handleSubmit, control, setError, clearErrors } = useForm({});
  const success = FindSuccess(ACCOUNT_ACTION_TYPE);
  const backError = FindError(ACCOUNT_ACTION_TYPE);

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACCOUNT_ACTION_TYPE));
      Notify("Profile Updated Successfully");
    }
  }, [success]);

  useEffect(() => {
    if (backError?.error === "User password does not match") {
      setError("password", {
        type: "required",
        message: "User password does not match",
      });
      dispatch(httpRequestsOnErrorsActions.removeError(PROFILE_ACTION_TYPE));
    }
  }, [backError]);

  const handleReset = () => {
    setShowActions(false);
    clearErrors();
  };

  const focusOn = () => {
    setShowActions(true);
  };

  const onSubmit = (data) => {
    if (data?.newPassword === data?.confirmation) {
      dispatch(authActions.changePassword(data));
    } else {
      setError("confirmation", {
        type: "required",
        message: "New Password does not match with the confirmation",
      });
    }
  };

  return (
    <div className="profile-account-wrapper">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="profile-account-body-box">
          <p className="account-header-title">Change Password</p>
          <div className="profile-account-box">
            <InputMain
              name="password"
              type="password"
              control={control}
              placeholder="Write Old Password"
              labelHeader="Current Password"
              onFocus={() => focusOn()}
              rules={{
                required: "This Field is required",
              }}
              startAdornment={<Svg.Lock />}
            />
            <InputMain
              name="newPassword"
              type="password"
              control={control}
              placeholder="Write New Password"
              labelHeader="New Password"
              onFocus={() => focusOn()}
              rules={{
                required: "This Field is required",
              }}
              startAdornment={<Svg.Lock />}
            />
            <InputMain
              name="confirmation"
              type="password"
              control={control}
              placeholder="Confirm New Password"
              labelHeader="Confirm New Password"
              onFocus={() => focusOn()}
              rules={{
                required: "This Field is required",
              }}
              startAdornment={<Svg.Lock />}
            />
          </div>
        </div>

        {showActions && (
          <div className="profile-account-actions-wrapper">
            <div className="profile-account-actions-box">
              <ModalActions
                closeFunction={handleReset}
                defaultStyle={true}
                type="submit"
                loadAction={ACCOUNT_ACTION_TYPE}
                saveTitle="Change"
              />
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
