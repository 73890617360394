import { DateRow, PriceRow, TextRow, TextToolTip } from "components";
import { ItemsAndTooltip } from "fragments";

export const ACTION_TYPE = "GET_FUNDRAISER_BY_ID";
export const ACTION_TYPE_DONATION = "GET_DONATIONS_FUNDRAISER";
export const ACTION_TYPE_DONATION_CREATE = "CREATE_DONATION";

export const contributionsHead = (width) => {
  return width > 1279
    ? [
        { title: "Name", sortType: "donorName" },
        { title: "Date", sortType: "createdAt" },
        { title: "Amount", sortType: "amount" },
        { title: "Item" },
        { title: "Message" },
      ]
    : [
        { title: "Name", sortType: "donorName" },
        { title: "Amount", sortType: "amount" },
        { title: "Item" },
      ];
};

export const contributionsBody = (width) => {
  return width > 1279
    ? [
        {
          rowText: (item) => <TextRow text={item?.donorName} />,
          width: "20%",
        },
        {
          rowText: (item) => <DateRow date={item?.createdAt} />,
          width: "18%",
        },
        {
          rowText: (item) => <PriceRow info={item?.amount} />,
          width: "18%",
        },
        {
          rowText: (item) =>
            item?.favors?.length ? (
              <ItemsAndTooltip items={item?.favors} />
            ) : (
              "N/A"
            ),
          width: "10%",
        },
        {
          rowText: (item) => <TextRow text={item?.description} />,
          width: "35%",
        },
      ]
    : [
        {
          rowText: (item) => <TextRow text={item?.donorName} />,
          width: "35%",
        },
        {
          rowText: (item) => <DateRow date={item?.createdAt} />,
          width: "35%",
        },
        {
          rowText: (item) =>
            item?.favors?.length ? (
              <ItemsAndTooltip items={item?.favors} />
            ) : (
              "N/A"
            ),
          width: "10%",
        },
      ];
};

export const contributionsAccordionDetails = (item) => {
  return (
    <div className="contribution-accordion-details">
      <div className="contribution-accordion-details-date">
        <span className="contribution-accordion-details-name">Date:</span>
        <DateRow date={item?.createdAt} />
      </div>

      {item?.description && (
        <TextToolTip
          lineClamp={2}
          text={
            <div className="contribution-accordion-details-description">
              <span className="contribution-accordion-details-name">
                Message:
              </span>{" "}
              {item?.description}
            </div>
          }
          content={item?.description}
        />
      )}
    </div>
  );
};
