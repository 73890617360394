import React, { useCallback, useState } from "react";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { Svg } from "assets";

export const DateRow = ({ date, color, format }) => {
  return (
    <p style={color ? { color: color } : {}}>
      {date ? moment.utc(date).format(format ? format : "MM/DD/YYYY") : "-"}
    </p>
  );
};

export const DateConvertor = (date) => {
  return date ? moment.utc(date).format("MM/DD/YYYY") : "N/A";
};

export const ImageAndName = ({
  text,
  textWidth,
  color,
  src,
  noSrc,
  onClick,
}) => {
  return (
    <div className="image-and-name-row">
      <div
        className="image-box"
        onClick={(e) => {
          if (onClick) {
            e.stopPropagation();
            onClick();
          }
        }}
      >
        {src ? <img src={src} alt={"icon"} /> : noSrc}
      </div>
      <TextRow text={text} textWidth={textWidth} color={color} />
    </div>
  );
};

export const LinkRow = ({ text, link }) => {
  return (
    <a className="link-row" href={link} target={"_blank"} rel="noreferrer">
      <TextRow text={text} />
    </a>
  );
};

export const TextRow = ({ text, textWidth, color }) => {
  const [currentWidth, setCurrentWidth] = useState(null);
  const width = currentWidth ? currentWidth : "auto";
  const textToShow = (text ? text : "-") + "";

  const curr = useCallback((node) => {
    if (node !== null) {
      setCurrentWidth(node.getBoundingClientRect().width);
    }
  }, []);

  return (
    <div ref={curr} className="full-width">
      {RenderText(width, textToShow, textWidth, color)}
    </div>
  );
};

export const PriceRow = ({ info, textWidth }) => {
  const [currentWidth, setCurrentWidth] = useState(null);
  const width = currentWidth ? currentWidth : 250;
  const price = (info ? info.toFixed(2) : 0) + "";

  const curr = useCallback((node) => {
    if (node !== null) {
      setCurrentWidth(node.getBoundingClientRect().width);
    }
  }, []);

  if (price) {
    const count = Math.ceil(width / (textWidth ? textWidth : 11));
    const slicedText = "" + price?.slice(0, count);
    return (
      <div ref={curr} className="full-width">
        {price?.length > count ? (
          <Tooltip
            placement="top"
            title={
              <p>
                <NumericFormat
                  value={price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </p>
            }
          >
            <p style={{ textOverflow: "ellipsis" }}>
              <NumericFormat
                value={slicedText}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
              ...
            </p>
          </Tooltip>
        ) : (
          <p>
            {
              <NumericFormat
                value={price}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            }
          </p>
        )}
      </div>
    );
  } else {
    return "...";
  }
};

export const PhoneRow = ({ phone }) => {
  if (phone) {
    const converted =
      "(" +
      phone.substr(0, 3) +
      ") " +
      phone.substr(3, 3) +
      "-" +
      phone.substr(6, 6);
    return converted;
  } else {
    return "-";
  }
};

export const TypeRow = ({ text }) => {
  if (text !== undefined) {
    return text?.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    });
  } else {
    return "...";
  }
};

const RenderText = (width, text, textWidth, color) => {
  if (text) {
    const count = Math.ceil(width / (textWidth ? textWidth : 8));
    const slicedText = "" + text?.slice(0, count);

    return (
      <div className="full-width" style={color ? { color: color } : {}}>
        {text?.length > count ? (
          <Tooltip placement="top" title={<p>{text}</p>}>
            <p style={{ textOverflow: "ellipsis" }}>{`${slicedText}...`}</p>
          </Tooltip>
        ) : (
          <p>{text}</p>
        )}
      </div>
    );
  } else {
    return "...";
  }
};

export const PriceConvertor = ({ price }) => {
  const convertedPrice = price ? price.toFixed(2) : 0;
  return (
    <NumericFormat
      value={convertedPrice ? convertedPrice : "0"}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
    />
  );
};

export const MobileCardHead = ({ src, noSrc, value, onClick }) => {
  return (
    <div className="mobile-card-head">
      {src && (
        <div
          className="mobile-card-head-image-box"
          onClick={(e) => {
            e.stopPropagation();
            onClick && onClick();
          }}
        >
          {src ? <img src={src} alt={"icon"} /> : noSrc}
        </div>
      )}
      <TextRow text={value} />
    </div>
  );
};

export const MobileCardHeadTitleName = ({ title, value }) => {
  return (
    <div className="mobile-card-head-box">
      <span className="mobile-card-footer-title">{title}</span>
      {value && <TextRow text={value} />}
    </div>
  );
};

export const MobileCardFooter = ({ title, value }) => {
  return (
    <div className="mobile-card-footer">
      <p className="mobile-card-footer-title">{title}</p>
      <p className="mobile-card-footer-value">{value}</p>
    </div>
  );
};

export const MobileCardFooterRender = ({ value }) => {
  return (
    <div className="mobile-card-footer" style={{ justifyContent: "center" }}>
      {value}
    </div>
  );
};

export const MobileCardBody = ({ title, value }) => {
  return (
    <div className="mobile-card-body">
      <p className="mobile-card-body-title">{title}</p>
      <p className="mobile-card-body-value">{value}</p>
    </div>
  );
};

export const ToolTipRow = ({ text }) => {
  return (
    <Tooltip placement="top" title={text}>
      <Svg.Info className="info-svg" />
    </Tooltip>
  );
};
