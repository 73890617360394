import React from "react";
import { Loader } from "components";
import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";

export const GoogleApplePay = ({
  stripe,
  elements,
  paymentRequest,
  disabled,
  onClick,
}) => {
  return (
    <button
      disabled={disabled}
      className="stripe-button-wrapper"
      style={
        disabled
          ? {
              pointerEvents: "none",
              opacity: 0.5,
            }
          : {}
      }
    >
      {!stripe || !elements || !paymentRequest ? (
        <Loader styles={{ color: "#FFF" }} />
      ) : (
        <PaymentRequestButtonElement
          onClick={(e) => {
            onClick && onClick(e);
          }}
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                theme: "dark",
                height: "48px",
                borderRadius: "8px",
                type: "donate",
              },
            },
          }}
          className="stripe-button"
        />
      )}
    </button>
  );
};
