import React from "react";
import { PriceRow, TextToolTip } from "components";
import { Svg } from "assets";

export const FavorSmallCard = ({
  item,
  className,
  onClick,
  activeItems = [],
}) => {
  return (
    <div
      className={`favor-small-card-wrapper
       ${
         activeItems.some((element) => element?.id === item?.id)
           ? "favor-small-card-active"
           : ""
       }
        ${className ? className : ""}`}
    >
      <div className="favor-small-card-box">
        <div className="favor-small-card-image">
          <img src={item?.image?.url} alt="favor" />

          <button
            type="button"
            className="favor-small-card_action-checkbox-button"
            onClick={(e) => {
              e.stopPropagation();
              onClick && onClick(item);
            }}
          >
            {activeItems.some((element) => element?.id === item?.id) ? (
              <Svg.CheckBoxChecked className="favor-small-card-checkbox-svg" />
            ) : (
              <Svg.CheckboxNotChecked className="favor-small-card-close-svg" />
            )}
          </button>
        </div>

        <div className="favor-small-card-price_title">
          <div className="favor-small-card-price">
            <PriceRow info={item.price} />
          </div>
          <TextToolTip
            text={item.name}
            content={item.name}
            classNameText="favor-small-card-name"
          />
        </div>
      </div>
    </div>
  );
};
