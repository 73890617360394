import React, { useState } from "react";
import moment from "moment/moment";
import { PriceRow, TextToolTip } from "components";
import { ItemsAndTooltip } from "fragments";

export const ContributionCard = ({ item }) => {
  const [showMessages, setShowMessages] = useState(false);
  return (
    <div className="contribution-card-wrapper">
      <div className="contribution-card-date-amount">
        <TextToolTip
          lineClamp={1}
          text={<p className="contribution-card-name">{item?.donorName}</p>}
          content={item?.donorName}
        />

        {!!item?.favors?.length && <ItemsAndTooltip items={item?.favors} />}
      </div>

      <div className="contribution-card-date-amount">
        <p className="contribution-card-date">
          {moment(item?.createdAt).format("MM/DD/YYYY")}
        </p>
        <p className="contribution-card-amount">
          <PriceRow info={item?.amount} />
        </p>
      </div>

      {item?.description && (
        <button
          className="contribution-card-button"
          onClick={() => setShowMessages((prevState) => !prevState)}
        >
          {!showMessages ? "See Message" : "Hide Message"}
        </button>
      )}

      {showMessages && item?.description && (
        <p className="contribution-card-messages">{item?.description}</p>
      )}
    </div>
  );
};
