import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { ButtonMain, PriceConvertor } from "components";
import { Images, Svg } from "assets";
import { JoinMovementCard } from "./joinMovementCard";
import { fundraiserActions } from "store";

export const JoinMovement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const info = location?.state;
  const { fundraisers } = useSelector((state) => ({
    fundraisers: state.fundraiser.fundraisersSearching,
  }));

  const firstFundraisers = fundraisers?.fundraisers?.length
    ? fundraisers?.fundraisers[0]
    : null;

  useEffect(() => {
    const params = {};

    if (info?.keyword) {
      params.keyword = info?.keyword;
    }

    dispatch(fundraiserActions.getFundraisersSearching(params));
  }, [dispatch, info?.keyword]);

  return (
    <div className="join-movement-wrapper">
      <div className="container">
        <div className="join-movement-header">
          <h2 className="join-movement-title">Join the Movement</h2>

          <ButtonMain
            classNameWrapper="ready-card-button-tablet"
            ClassName="ready-card-button"
            text={"Find More"}
            onClick={() => {
              navigate("/find-event");
            }}
            endIcon={<Svg.ArrowRightBlack />}
          />
        </div>

        <div className="join-movement-content-desktop">
          {firstFundraisers && (
            <JoinMovementCard
              onClick={() => navigate(`/event/${firstFundraisers?.id}`)}
              classNameWrapper="join-movement-content-left-card"
              item={{
                ...firstFundraisers,
                images:
                  firstFundraisers?.images?.length > 0
                    ? firstFundraisers?.images
                    : [
                        {
                          url: Images.needCardFrameWhite,
                        },
                      ],
                title: firstFundraisers?.name,
                price: PriceConvertor({ price: firstFundraisers?.balance }),
                percentage: "52%",
                description: "",
              }}
            />
          )}

          <div className="join-movement-content-right-cards">
            {!!fundraisers?.fundraisers?.length &&
              fundraisers?.fundraisers?.slice(1, 5)?.map((item, index) => (
                <JoinMovementCard
                  onClick={() => navigate(`/event/${item?.id}`)}
                  classNameWrapper="right-single-card"
                  item={{
                    ...item,
                    images:
                      item?.images?.length > 0
                        ? item?.images
                        : [
                            {
                              url: Images.needCardFrameWhite,
                            },
                          ],
                    title: item?.name,
                    price: PriceConvertor({ price: item?.balance }),
                    percentage: "52%",
                    description: "",
                  }}
                  key={index}
                />
              ))}
          </div>
        </div>

        <Swiper
          className="join-movement-carousel-wrapper"
          spaceBetween={16}
          slidesPerView={1.2}
        >
          {!!fundraisers?.fundraisers?.length &&
            fundraisers?.fundraisers?.map((item, index) => (
              <SwiperSlide key={index}>
                <JoinMovementCard
                  onClick={() => navigate(`/event/${item?.id}`)}
                  classNameWrapper="join-movement-carousel-card"
                  item={{
                    ...item,
                    images:
                      item?.images?.length > 0
                        ? item?.images
                        : [
                            {
                              url: Images.needCardFrameWhite,
                            },
                          ],
                    title: item?.name,
                    price: PriceConvertor({ price: item?.balance }),
                    percentage: "52%",
                    description: "",
                  }}
                />
              </SwiperSlide>
            ))}
        </Swiper>

        <ButtonMain
          classNameWrapper="ready-card-button-mobile"
          text={"Find More"}
          onClick={() => {
            navigate("/find-event");
          }}
          endIcon={<Svg.ArrowRightBlack />}
        />
      </div>
    </div>
  );
};
