import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Popper from "@mui/material/Popper";
import { Svg } from "assets";
import { ProfileInfo } from "utils";
import { authActions, fundraiserActions } from "store";
import { LoggedAdminLinks, LoggedUserLinks } from "./constants";

export const LoggedUser = ({ headerItemsColor, isMemory }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { myProfile, ifAdmin } = ProfileInfo();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const list = ifAdmin ? LoggedAdminLinks : LoggedUserLinks;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const logOut = () => {
    dispatch(authActions.logOut());
    setAnchorEl(null);
    // navigate("/");
  };

  const handleNavigate = (link) => {
    setAnchorEl(null);
    dispatch(fundraiserActions.resetFundraiserById());
    navigate(link);
  };

  return (
    <div>
      <div>
        <button
          className="logged-popper"
          aria-describedby={id}
          type="button"
          onClick={handleClick}
        >
          <p
            style={{
              color: headerItemsColor,
            }}
          >
            {myProfile?.username}
          </p>

          <div className="logged-popper-image">
            {myProfile?.avatar?.url ? (
              <img src={myProfile?.avatar?.url} alt="avatar" />
            ) : location.pathname === "/in-memory-of" || isMemory ? (
              <Svg.ProfilePropertyMemory />
            ) : (
              <Svg.ProfileProperty />
            )}
          </div>

          <Svg.ArrowDown
            className="logged-popper-arrow"
            style={{
              transform: anchorEl ? "rotate(-180deg)" : "rotate(0)",
              transition: "all .3s",
              "--color":
                location.pathname === "/in-memory-of"
                  ? "#ffffff"
                  : headerItemsColor,
            }}
          />
        </button>
      </div>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={"custom-select-paper-box-custom"}
        placement="bottom-start"
        style={{ position: "absolute", zIndex: 20 }}
      >
        <div
          className={`logged-links-box ${
            isMemory ? "logged-links-box_memory" : ""
          }`}
        >
          <div className="logged-links-wrapper">
            {list?.map((i, j) => (
              <button
                className={`link-btn ${
                  location?.pathname.startsWith(i?.link)
                    ? "link-btn-active"
                    : ""
                }`}
                key={j}
                onClick={() => handleNavigate(i?.link)}
              >
                {i?.icon}
                {i?.title}
              </button>
            ))}
          </div>
          <div className="log-out-box">
            <button onClick={logOut} className="link-btn log-out">
              <Svg.Logout />
              Log Out
            </button>
          </div>
        </div>
      </Popper>

      {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
    </div>
  );
};
