import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { BackBtn, ButtonMain, PageLoader, SearchInput } from "components";
import { FavorSmallCard, SortBy, sortItems } from "fragments";
import { FindLoad, SaveParams } from "utils";
import { favorsActions } from "store";
import { toast } from "react-toastify";

export const FavorItems = () => {
  const [showActiveItem, setShowActiveItem] = useState([]);
  const { favorsForFilter } = useSelector((state) => ({
    favorsForFilter: state.favors?.favorsForFilter?.favors,
  }));

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const info = location?.state;
  const loading = FindLoad("GET_FAVORS_FOR_FILTER");
  const activeItems = info?.activeItems;
  const amount = info?.amount;

  useEffect(() => {
    if (activeItems?.length) {
      setShowActiveItem(activeItems);
    }
  }, [activeItems]);

  useEffect(() => {
    const params = {
      ...info,
      type: info?.type,
    };
    params.skip = info?.skip ? info?.skip : 0;
    delete params?.page;
    delete params?.sortValue;

    dispatch(favorsActions.getFavorsForFilter(params));
  }, [
    dispatch,
    info?.skip,
    info?.limit,
    info?.orderType,
    info?.sortType,
    info?.name,
    info?.type,
  ]);

  const toggleItems = (item) => {
    let amountItems = 0;

    showActiveItem.forEach((element) => {
      amountItems += element?.price;
    });

    if (showActiveItem.some((element) => element?.id === item?.id)) {
      setShowActiveItem((prevState) =>
        prevState.filter((element) => element?.id !== item?.id)
      );
    } else {
      if (amountItems + item?.price <= amount) {
        setShowActiveItem([...showActiveItem, item]);
      } else {
        toast("You can't add more items");
      }
    }
  };

  return (
    <div className="favor-items-page">
      <div className="container">
        <div className="favor-items-box">
          <div className="favor-items-page-back-btn-box">
            <BackBtn
              handleClick={() =>
                navigate(`/donate/${info?.id}`, {
                  state: { ...info },
                })
              }
            />
          </div>

          <div>
            <p className="favor-items-choose-item">Choose Items</p>
            {location?.state?.amount && (
              <p className="favor-items-choose-budget">{`Budget: ${location?.state?.amount}`}</p>
            )}
            <div className="favor-items-page-search_input-filter">
              <SearchInput
                placeholder={"Search by name or cost..."}
                searchKey={"name"}
                className="see-items-search_input"
              />

              <SortBy
                items={sortItems}
                classNameSortByButton="favor-items-sort-by-button"
              />
            </div>

            <div className="favor-items-page_items-box">
              {loading ? (
                <PageLoader
                  height={"100%"}
                  wrapperHeight={"100%"}
                  wrapperWidth={"100%"}
                />
              ) : (
                !!favorsForFilter?.length &&
                favorsForFilter?.map((item, index) => (
                  <FavorSmallCard
                    className="favor-items-see-items_single"
                    item={item}
                    key={index}
                    onClick={(item) => toggleItems(item)}
                    handleDelete={(item) => toggleItems(item)}
                    activeItems={showActiveItem}
                  />
                ))
              )}
            </div>
          </div>

          <div className="favor-items-page-footer">
            <button
              type="button"
              className="modal2-footer-cancel-button"
              onClick={() => navigate(`/donate/${info?.id}`)}
            >
              Cancel
            </button>
            <ButtonMain
              type="button"
              text="Send"
              onClick={() => {
                SaveParams(`/donate/${info?.id}`, navigate, {
                  ...info,
                  activeItems: [...showActiveItem],
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
