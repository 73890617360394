import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  DeleteModal,
  FullTable,
  FullTableAccordionMain,
  SearchInput,
  TitleAndAction,
} from "components";
import { Svg } from "assets";
import { useModal, useWindowDimensions } from "utils";
import {
  ACTION_TYPES,
  favorsAccordionDetails,
  favorsBody,
  favorsHead,
  favorsMobileCard,
} from "./fragments/constants";
import { CreateFavor } from "./fragments";
import { favorsActions } from "store";
import { FavorsImagesSlide } from "../../fragments";

export const Favors = () => {
  const { favors } = useSelector((state) => ({
    favors: state.favors.favors,
  }));
  const dispatch = useDispatch();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const { openModal } = useModal();

  const info = location?.state;

  useEffect(() => {
    const params = {
      ...info,
      limit: 10,
    };

    params.skip = info?.skip ? info?.skip : 0;
    delete params.page;
    delete params.tab;
    if (params?.sortValue) delete params.sortValue;

    dispatch(favorsActions.getFavors(params));
  }, [dispatch, info]);

  const handleClick = (item, name) => {
    if (name === "edit") {
      openModal(<CreateFavor item={item} />, {
        padding: "noPaddings",
        noShowCloseBrn: "false",
      });
    }

    if (name === "delete") {
      openModal(
        <DeleteModal
          customIcon={<Svg.DeleteCharity />}
          title={"Are you sure you want to Delete Favor?"}
          description={
            "This action cannot be undone. Deleting this favor will permanently remove all associated data."
          }
          handleDelete={() => {
            const params = {
              ...info,
              limit: 10,
              id: item?.id,
            };
            params.skip = info?.skip ? info?.skip : 0;
            delete params.page;
            delete params.tab;
            dispatch(favorsActions.deleteFavor(params));
          }}
          actionType={ACTION_TYPES.DELETE_FAVOR}
        />,
        {
          padding: "noPaddings",
          noShowCloseBrn: true,
        }
      );
    }
  };

  const clickToImageItem = (items) => {
    openModal(<FavorsImagesSlide items={[items]} />, {
      padding: "noPaddings",
      noShowCloseBrn: true,
      className: "favors-images-slide-modal",
    });
  };

  return (
    <div className="favors-page">
      <TitleAndAction
        title="Items"
        handleClick={() =>
          openModal(<CreateFavor />, {
            padding: "noPaddings",
            noShowCloseBrn: "false",
          })
        }
        btnText="Create Favor"
        startIcon={<Svg.AddWhiteCircle />}
        mobileBtnText="notShow"
      />

      <SearchInput
        placeholder={"Search by name..."}
        searchKey={"name"}
        className="favors-page-search_input"
      />

      {width > 767 && width < 1279 ? (
        <FullTableAccordionMain
          head={favorsHead(width)}
          body={favorsBody(width, clickToImageItem)}
          accordionDetails={favorsAccordionDetails(handleClick)}
          loadingType={ACTION_TYPES.GET_FAVORS}
          list={favors?.favors}
          listCount={favors?.count}
        />
      ) : (
        <FullTable
          head={favorsHead(width)}
          body={favorsBody(width, clickToImageItem)}
          mobileCard={favorsMobileCard(handleClick, clickToImageItem)}
          loadingType={ACTION_TYPES.GET_FAVORS}
          list={favors?.favors}
          listCount={favors?.count}
          handleClick={handleClick}
        />
      )}
    </div>
  );
};
