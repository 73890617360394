import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { InputMain, MinLoader, ModalActions, PhoneInput } from "components";
import {
  EmailValidator,
  FindError,
  FindSuccess,
  Notify,
  ProfileInfo,
} from "utils";
import {
  httpRequestsOnErrorsActions,
  httpRequestsOnSuccessActions,
  UploadServices,
  userActions,
} from "store";
import { PROFILE_ACTION_TYPE } from "./constants";
import { Svg } from "assets";
import { Account } from "./account";

const fileTypes = ["JPEG", "JPG", "PNG", "GIF"];

export const Profile = () => {
  const [fileError, setFileError] = useState("");
  const [currentFile, setCurrentFile] = useState(null);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [isHoveredProfile, toggleHoveredProfile] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const { handleSubmit, control, setError, reset, clearErrors } = useForm({});

  const { myProfile } = ProfileInfo();
  const dispatch = useDispatch();
  const backError = FindError(PROFILE_ACTION_TYPE);
  const success = FindSuccess(PROFILE_ACTION_TYPE);

  const handleReset = () => {
    reset({
      firstName: myProfile?.firstName,
      username: myProfile?.username,
      lastName: myProfile?.lastName,
      email: myProfile?.email,
      phoneNumber: myProfile?.phoneNumber,
    });

    setShowActions(false);
    clearErrors();
  };

  useEffect(() => {
    if (myProfile) {
      handleReset();

      setCurrentFile(myProfile?.avatar);
    }
  }, [myProfile]);

  useEffect(() => {
    if (
      backError?.error === "User with this email or phone number exists" ||
      backError?.error === "User with this email address already exists"
    ) {
      setError("email", {
        type: "required",
        message: "User with this email exists",
      });
      dispatch(httpRequestsOnErrorsActions.removeError(PROFILE_ACTION_TYPE));
    }
    if (backError?.error === "User password does not match") {
      setError("password", {
        type: "required",
        message: "User password does not match",
      });
      dispatch(httpRequestsOnErrorsActions.removeError(PROFILE_ACTION_TYPE));
    }
    if (success?.type === PROFILE_ACTION_TYPE) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(PROFILE_ACTION_TYPE));
      Notify("Profile Updated Successfully");
    }
  }, [success, backError]);

  const onSubmit = (data) => {
    const params = {
      ...data,
    };

    if (data?.phoneNumber) {
      params.phoneNumber = parseInt(data?.phoneNumber.replace(/[^0-9]/g, ""));
    } else {
      params.phoneNumber = null;
    }

    dispatch(userActions.editUser(myProfile?.id, params));
  };

  const handleUpload = async (e) => {
    fileError === "currentFile" && setFileError("");
    if (e) {
      let formData = new FormData();
      formData.append("files", e);
      try {
        setUploadLoader(true);
        const res = await UploadServices.UploadImage(formData);
        setCurrentFile(res?.data);
        const params = {
          ...myProfile,
        };
        params.avatar = res?.data;
        dispatch(userActions.editUser(myProfile?.id, params));
        setUploadLoader(false);
      } catch (e) {
        setUploadLoader(false);
      }
    }
  };

  const deleteProfileImage = () => {
    setCurrentFile(null);
    const params = {
      ...myProfile,
    };
    params.avatar = null;
    dispatch(userActions.editUser(myProfile?.id, params));
  };

  const focusOn = () => {
    setShowActions(true);
  };

  return (
    <div className="profile-account-block">
      <div className="profile-account-wrapper">
        <div className="upload-images-wrapper">
          <FileUploader
            handleChange={handleUpload}
            name="file"
            fileTypes={fileTypes}
            onDraggingStateChange={(isDragging) => {
              toggleHoveredProfile(isDragging);
            }}
            dropMessageStyle={{ display: "none" }}
          >
            <div className="drag-drop-component">
              <div
                className={`profile-avatar-box
                                ${
                                  !currentFile?.url ? "my-profile-no-image" : ""
                                }
                                  ${
                                    isHoveredProfile
                                      ? "my-profile-image-hover"
                                      : ""
                                  }
                                `}
                onDragEnter={() => toggleHoveredProfile(true)}
                onDragLeave={() => toggleHoveredProfile(false)}
              >
                {!isHoveredProfile ? (
                  uploadLoader ? (
                    <MinLoader
                      color="#004080"
                      height="30px"
                      width="30px"
                      margin={"0"}
                    />
                  ) : currentFile?.url ? (
                    <img src={currentFile?.url} alt="icon" />
                  ) : (
                    <Svg.NotUploaded />
                  )
                ) : (
                  <div className="hovered-img-block-svg">
                    <Svg.UploadCloudSvg />
                  </div>
                )}
              </div>
            </div>
          </FileUploader>

          <div className="upload-images-actions">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="upload-image-button" htmlFor="file">
              <Svg.Edit />
            </label>

            {currentFile?.url && (
              <button
                className="upload-image-button"
                onClick={deleteProfileImage}
              >
                <Svg.Trash />
              </button>
            )}
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="profile-account-body-box">
            <div className="profile-account-box">
              <div className="profile-account-firstName_username">
                <InputMain
                  name="firstName"
                  type="text"
                  control={control}
                  placeholder="John"
                  labelHeader="First Name"
                  maxLength={20}
                  onFocus={() => focusOn()}
                  rules={{
                    required: "This Field is required",
                  }}
                />

                <InputMain
                  name="lastName"
                  type="text"
                  control={control}
                  placeholder="Smith"
                  labelHeader="Last Name"
                  maxLength={20}
                  onFocus={() => focusOn()}
                  rules={{
                    required: "This Field is required",
                  }}
                />
              </div>

              <InputMain
                name="username"
                type="text"
                control={control}
                placeholder="Smith"
                labelHeader="Username"
                maxLength={20}
                onFocus={() => focusOn()}
                rules={{
                  required: "This Field is required",
                }}
              />

              <InputMain
                name="email"
                type="email"
                control={control}
                placeholder="admin@gmail.com"
                labelHeader="Email"
                onFocus={() => focusOn()}
                rules={{
                  required: "This Field is required",
                  pattern: {
                    value: EmailValidator,
                    message: "Must be valid email",
                  },
                }}
              />
              <PhoneInput
                placeholder={"ex (000) 000-0000"}
                name="phoneNumber"
                control={control}
                onFocus={() => focusOn()}
                labelHeader="Phone Number"
              />
            </div>
          </div>

          {showActions && (
            <div className="profile-account-actions-wrapper">
              <div className="profile-account-actions-box">
                <ModalActions
                  defaultStyle={true}
                  type="submit"
                  loadAction={PROFILE_ACTION_TYPE}
                  saveTitle="Save"
                  closeFunction={handleReset}
                />
              </div>
            </div>
          )}
        </form>
      </div>
      <Account />
    </div>
  );
};
