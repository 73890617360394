import { useWindowSize } from "utils";
import { useEffect, useState } from "react";
import { Images } from "assets";

export const Transform = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [width] = useWindowSize();

  useEffect(() => {
    const img = new Image();
    img.src =
      width > 1279
        ? Images.homeBanner
        : width > 767
        ? Images.homeBannerTablet
        : Images.homeBannerMobile;
    img.onload = () => setImageLoaded(true);
  }, [width]);

  return (
    <div className="transform-wrapper">
      <div className="transform_background-small" />
      <div
        className="transform__background-big"
        style={{
          opacity: imageLoaded ? 1 : 0,
          transition: "opacity 0.5s ease-in-out",
        }}
      />
      <div className="transform-section">
        <div className="transform-section-box">
          <p className="transform-title">Transform Gifts into Blessings</p>
          <p className="transform-subtitle">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Whether it's a birthday, wedding, or honoring a loved one's memory,
            invite your guests to donate to a cause close to your heart instead
            of traditional gifts or flowers. Join our community of givers and
            celebrate with purpose.
          </p>
        </div>
      </div>
    </div>
  );
};
