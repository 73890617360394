import React from "react";
import { ButtonMain, DateConvertor, TextToolTip } from "components";
import { fundraiserStatuses } from "../../fundraisers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

export const JoinMovementCard = ({
  item,
  classNameWrapper,
  stickerLive,
  allowButtons,
  showFirstButton,
  onClickFirstButton,
  onClickSecondButton,
  onClick,
}) => {
  const classes = ["join-movement-card-wrapper"];

  if (classNameWrapper) classes.push(classNameWrapper);

  return (
    <div className={classes.join(" ")} onClick={onClick}>
      <div className="sticker-img-wrapper">
        <Swiper
          className="join-movement-card-img-block"
          modules={[Pagination]}
          pagination={{ clickable: true }}
        >
          {item?.images?.length &&
            item?.images?.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  className="join-movement-card-im"
                  src={image?.url}
                  alt={`Slide ${index + 1}`}
                />
              </SwiperSlide>
            ))}
        </Swiper>

        {stickerLive && (
          <div
            className={`join-movement-sticker
             ${
               item?.type === "MEMORY"
                 ? ""
                 : "join-movement-sticker-celebration"
             }
             ${
               item?.status !== fundraiserStatuses.LIVE
                 ? "join-movement-sticker-dont-live"
                 : ""
             }
             `}
          >
            <div className="join-movement-sticker-circle" />

            {stickerLive || "Live"}
          </div>
        )}
      </div>

      <div className="join-movement-card_content">
        {(item?.price || item?.percentage) && (
          <div className="price-percentage-wrapper">
            <p className="price">
              <span>Total Amount: </span>
              {item?.price}
            </p>

            {/*<div className="percentage-wrapper">*/}
            {/*  <div*/}
            {/*    className="percentage-block"*/}
            {/*    style={{ width: item?.percentage }}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        )}

        {(item?.title || item?.description) && (
          <TextToolTip
            classNameText="join-movement-card-title"
            text={`${item?.title}`}
            content={`${item?.title}`}
          />
        )}

        {item?.secondDescription && (
          <p className="join-movement-card-desc">{item?.secondDescription}</p>
        )}

        {item?.endDate && (
          <TextToolTip
            classNameText={"join-movement-card-end-date"}
            content={`End Date: ${DateConvertor(item?.endDate)} ${
              item?.type === "MEMORY"
                ? "In Memory Event"
                : "In Celebration Event"
            }`}
            text={`End Date: ${DateConvertor(item?.endDate)} ${
              item?.type === "MEMORY"
                ? "In Memory Event"
                : "In Celebration Event"
            }`}
          />
        )}

        {allowButtons && (
          <div className="buttons-block">
            {showFirstButton && (
              <ButtonMain
                classNameWrapper="donate-now-button"
                text="Donate now"
                type="button"
                onClick={onClickFirstButton}
              />
            )}

            <ButtonMain
              classNameWrapper="see-detail"
              text="See Details"
              type="button"
              onClick={onClickSecondButton}
            />
          </div>
        )}
      </div>
    </div>
  );
};
