import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Thumbs } from "swiper/modules";
import { Svg } from "assets";

export const FavorsImagesSlide = ({ items }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="favors-images-two-slides-wrapper">
      <div className="favors-images-slide-main-slider-wrapper">
        <Swiper
          className="favors-images-slide-main-slider-box"
          modules={[Navigation, Pagination, Thumbs]}
          thumbs={{ swiper: thumbsSwiper }}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onSwiper={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
        >
          {items?.map((item, index) => (
            <SwiperSlide key={index}>
              <img
                src={item?.image?.url}
                alt={`Slide ${index + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  background: "#D9D9D9",
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <button ref={prevRef} className="favors-images-slide-main-slider-next">
          <Svg.ArrowRight />
        </button>
        <button ref={nextRef} className="favors-images-slide-main-slider-prev">
          <Svg.ArrowRight />
        </button>
      </div>

      <Swiper
        className="favors-images-slide-thumbs-slider-box"
        modules={[Navigation, Pagination, Thumbs]}
        onSwiper={setThumbsSwiper}
        spaceBetween={0}
        breakpoints={{
          640: {
            slidesPerView: 4,
            slidesPerGroup: 1,
          },
          768: {
            slidesPerView: 4,
            slidesPerGroup: 1,
          },
          1024: {
            slidesPerView: 4,
            slidesPerGroup: 1,
          },
          1280: {
            slidesPerView: 4,
            slidesPerGroup: 1,
          },
        }}
      >
        {items?.length > 1 &&
          items?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="small-swipe-items">
                <img
                  src={item?.image?.url}
                  alt={`Slide ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    background: "#D9D9D9",
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};
