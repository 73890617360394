import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DetailPageWrapper,
  PageLoader,
  PhoneRow,
  TitleValue,
} from "components";
import { GET_MY_BANK } from "../../charityDetails/fragments/constants";
import { FindLoad, FindSuccess, ProfileInfo, useModal } from "utils";
import { bankAccountActions, httpRequestsOnSuccessActions } from "store";
import { Svg } from "assets";
import { Requirements } from "../../charityDetails/fragments";
import { NoConnectedBank } from "../../charityDetails/fragments/noConnectedBank";
import { UpdateAccountDetails } from "fragments";
import { DELETE_CONNECT_STRIPE } from "./constants";
import { BankAccountUser, ConnectBankAccount } from "./connectBankAccount";

export const BankAccount = () => {
  const dispatch = useDispatch();
  const { openModal, close } = useModal();
  const { myProfile } = ProfileInfo();
  const { bankAccount } = useSelector((state) => ({
    bankAccount: state.bankAccount.bankAccount,
  }));
  const loader = FindLoad(GET_MY_BANK);
  const successDeletingStripe = FindSuccess(DELETE_CONNECT_STRIPE);

  useEffect(() => {
    if (successDeletingStripe) {
      if (myProfile?.pmtAccount?._id) {
        dispatch(bankAccountActions.getMyBank(myProfile?.pmtAccount?._id));
      }
      close();
      dispatch(
        httpRequestsOnSuccessActions.removeSuccess(DELETE_CONNECT_STRIPE)
      );
    }
  }, [successDeletingStripe]);

  useEffect(() => {
    if (myProfile?.pmtAccount?._id) {
      dispatch(bankAccountActions.getMyBank(myProfile?.pmtAccount?._id));
    }
  }, [dispatch]);

  if (loader) {
    return <PageLoader />;
  }

  return (
    <div className="charity-bank-account-wrapper">
      {bankAccount?.accountId && myProfile?.pmtAccount?._id ? (
        <>
          <DetailPageWrapper
            head={
              <>
                <p className="detail-page-title">Bank Account</p>
                <div className="fundraiser-detail-actions desktop">
                  <button
                    className="fundraiser-detail-refresh_button"
                    onClick={() =>
                      openModal(
                        <UpdateAccountDetails id={myProfile?.pmtAccount?._id} />
                      )
                    }
                  >
                    <Svg.Refresh3Svg /> Update Account Details
                  </button>
                </div>
              </>
            }
            body={
              <div className="nonprofit-details">
                <div className="nonprofit-details-wrapper">
                  <div className="nonprofit-details-box">
                    <p className="nonprofit-details_title">Personal Details</p>
                    <TitleValue
                      title="First Name"
                      value={bankAccount?.individual?.firstName}
                    />
                    <TitleValue
                      title="Last Name"
                      value={bankAccount?.individual?.lastName}
                    />
                    <TitleValue
                      title="Date of Birthday"
                      value={`${String(
                        bankAccount?.individual?.dob?.month
                      ).padStart(2, "0")}/${String(
                        bankAccount?.individual?.dob?.day
                      ).padStart(2, "0")}/${
                        bankAccount?.individual?.dob?.year
                      }`}
                    />
                    <TitleValue
                      title="Phone Number"
                      value={
                        <PhoneRow phone={bankAccount?.individual?.phone} />
                      }
                    />

                    <TitleValue
                      title=" Address"
                      value={
                        bankAccount?.individual?.address.includes("null")
                          ? "-"
                          : bankAccount?.individual?.address
                      }
                    />
                  </div>
                </div>

                <div className="fundraiser-detail-actions mobile">
                  <button
                    className="fundraiser-detail-refresh_button"
                    onClick={() =>
                      openModal(
                        <UpdateAccountDetails id={myProfile?.pmtAccount?._id} />
                      )
                    }
                  >
                    <Svg.Refresh3Svg /> Update Account Details
                  </button>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  {bankAccount?.requirements.map((item, i) => (
                    <Requirements errorMessage={item} key={i} />
                  ))}
                </div>
              </div>
            }
          />

          <div>
            <DetailPageWrapper
              body={
                <div>
                  <p className="detail-page-title">Connected Bank Account</p>
                  <div className="connected-bank-wrapper">
                    <div className="bank-information-box">
                      <Svg.ConnectedBank />
                      <div>
                        <p className="bank-name">
                          {bankAccount?.bankAccount?.bankName || "-"}
                        </p>
                        <p className="bank-current-account">
                          Bank Current Account{" "}
                        </p>
                        <p className="bank-routing">
                          **** **** {bankAccount?.bankAccount?.last4}
                        </p>
                      </div>
                    </div>

                    <div className="connect_bank_buttons">
                      {/*<button className="connect_bank_delete-button">*/}
                      {/*  <Svg.RedTrashFill />*/}
                      {/*  Delete*/}
                      {/*</button>*/}

                      <button
                        className="connect_bank_update-button"
                        onClick={() =>
                          openModal(
                            <BankAccountUser
                              info={
                                bankAccount?.bankAccount
                                  ? {
                                      routingNumber:
                                        bankAccount?.bankAccount?.routingNumber,
                                    }
                                  : null
                              }
                            />,
                            {
                              padding: "noPaddings",
                              noShowCloseBrn: true,
                            }
                          )
                        }
                      >
                        <Svg.Refresh3Svg />
                        {bankAccount?.bankAccount ? "Update" : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </>
      ) : (
        <NoConnectedBank
          onClick={() =>
            openModal(<ConnectBankAccount />, {
              padding: "noPaddings",
              noShowCloseBrn: true,
            })
          }
        />
      )}
    </div>
  );
};
