import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Svg } from "assets";
import axios from "axios";
import { Loader } from "components";

const fileTypes = ["JPEG", "JPG", "PNG", "GIF"];

export const Upload = ({
  classNameWrapper,
  UploadImage,
  handleChange,
  handleSetMain,
  backImages,
  handleDelete,
  loaderUpload,
  create,
  createMany,
  setDefaultUrls,
}) => {
  const [fileAdded, setFileAdded] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [error, setError] = useState([]);
  const [main, setMain] = useState(0);

  useEffect(() => {
    if (setDefaultUrls && backImages) {
      let newArr = [];
      backImages?.map((item) =>
        newArr.push({
          url: URL.createObjectURL(
            new File([item], "image", { type: "text/json;charset=utf-8" })
          ),
          id: newArr?.length + 1,
        })
      );
      setFileAdded(backImages);
      setUploadedImages(newArr);
      newArr = [];
    }
  }, [backImages]);

  useEffect(() => {
    if (backImages?.images?.length) {
      setUploadedImages(backImages?.images);
    }
    if (backImages?.mainImage) {
      setMain(backImages.mainImage);
    }
  }, [backImages]);

  const handleMain = (index) => {
    setMain(index);
    handleSetMain(index);
  };
  const handleSelectFile = async (e) => {
    setFileAdded(e);

    const newArr = [...uploadedImages];

    for (let item of e) {
      if (item && item.size > 2097152) {
        setError(true);
      } else {
        setError("");
        newArr.push({
          url: URL.createObjectURL(
            new File([item], "image", { type: "text/json;charset=utf-8" })
          ),
          id: newArr.length + 1,
        });
        setUploadedImages(newArr);
      }
    }

    if (create) {
      for (let item of e) {
        if (item && item.size > 2097152) {
          setError(true);
        } else {
          setError("");
          handleChange(item);
        }
      }
    } else if (createMany) {
      const formData = new FormData();
      const endpoint = `/files/uploadMany`;
      [...e].length && [...e].map((i) => formData.append("files", i));
      await axios
        .post(endpoint, formData, { auth: true })
        .then((res) => {
          handleChange({
            files: res?.data?.map((item) => {
              return {
                ...item,
                thumbUrl: item?.url,
              };
            }),
            mainImage: main,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      const formData = new FormData();
      const endpoint = `/files/upload`;
      formData.append("files", e[0]);

      await axios
        .post(endpoint, formData, { auth: true })
        .then((res) => {
          handleChange({
            file: {
              ...res.data,
              thumbUrl: res?.data?.url,
            },
            mainImage: main,
          });
          return res.data;
        })
        .catch(() => {});
      setError("");
    }
  };

  const handleDeleteImage = (e, item, index) => {
    e.preventDefault();
    e.stopPropagation();
    if (create) {
      handleDelete(item, index);
    } else {
      handleDelete(item);
    }
    const newArr = [...uploadedImages];
    newArr.splice(index, 1);
    setUploadedImages(newArr);
    setMain(0);
  };

  return (
    <div
      className={`upload-section-box ${
        classNameWrapper ? classNameWrapper : ""
      }`}
    >
      <div className="file-uploader">
        <FileUploader
          fileOrFiles={fileAdded}
          multiple={true}
          types={fileTypes}
          handleChange={handleSelectFile}
          name="file"
        >
          <div className="upload-info-box">
            {loaderUpload ? (
              <Loader
                type="ThreeDots"
                color="#FFFFFF"
                height={16}
                width={16}
                style={{ margin: "10px", padding: "0" }}
              />
            ) : (
              UploadImage || <Svg.ImageUpload />
            )}

            <p className="drag-drop-text">
              Drag & Drop or
              <div>
                <input style={{ display: "none" }} />
                <i className="fa fa-cloud-upload" />
                <span>Browse</span>
              </div>
              Images
            </p>
            {error === true ? (
              <p>Max size must be 2 MB</p>
            ) : (
              <div className="information-box">
                <p>
                  Supported formats: <b>pdf, jpeg, png</b>
                </p>
                <p>Max size for each image 2 MB</p>
              </div>
            )}
          </div>
        </FileUploader>
      </div>

      <div className="uploaded-images-box">
        {uploadedImages?.length
          ? uploadedImages.map((i, j) => (
              <div
                style={{
                  border:
                    main === j ? "1px solid #808000" : "1px solid transparent",
                }}
                onClick={() => handleMain(j)}
                key={j}
                className="uploaded-image"
              >
                <img src={i?.url} alt={`uploaded ${j}`} />
                <button
                  type="button"
                  className="remove-image"
                  onClick={(e) => handleDeleteImage(e, i, j)}
                >
                  <Svg.Close />
                </button>
              </div>
            ))
          : ""}
      </div>
    </div>
  );
};
