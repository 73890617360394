import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Swiper, SwiperSlide } from "swiper/react";
import Checkbox from "@mui/material/Checkbox";
import { BackBtn, ButtonMain, PriceRow } from "components";
import {
  AccordionRadio,
  FavorSmallCard,
  GoogleApplePay,
  ItemsAndTooltip,
} from "fragments";
import { ACTION_TYPE, ItemsModal } from "./fragments";
import {
  donationActions,
  favorsActions,
  fundraiserActions,
  httpRequestsOnSuccessActions,
} from "store";
import { Images, Svg } from "assets";
import {
  FindLoad,
  FindSuccess,
  SaveParams,
  useModal,
  useWindowSize,
} from "utils";
import { toast } from "react-toastify";
import { donationService } from "../../store/donations/donation.service";

export const DonateCheckoutForm = () => {
  const { fundraiserById, favors } = useSelector((state) => ({
    fundraiserById: state.fundraiser.fundraiserById,
    favors: state.favors?.favors?.favors,
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [payType, setPayType] = useState("card");
  const [agree, setAgree] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    watch,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm({
    shouldFocusError: true,
  });
  const { openModal } = useModal();
  const [width] = useWindowSize();
  const loadCreation = FindLoad(ACTION_TYPE);
  const success = FindSuccess(ACTION_TYPE);
  const info = location?.state;
  const activeItems = info?.activeItems || [];
  const [debouncedAmount] = useDebounce(info?.amount, 500);

  const amount = watch("amount");

  useEffect(() => {
    if (!stripe || !elements) {
      console.error("Stripe failed to initialize");
      return;
    }

    const amountInCents = Math.round((+debouncedAmount || 0) * 100);

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Demo payment",
        amount: amountInCents,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment()
      .then((result) => {
        if (result) {
          setPaymentRequest({
            paymentRequest: pr,
            result,
          });
        } else {
          console.log("Google or Apple Pay is not available");
        }
      })
      .catch((error) => {
        toast.error(`Payment availability check error: ${error}`);
      });
  }, [stripe, elements]);

  useEffect(() => {
    if (success) {
      reset({});
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
      navigate("/payment-success");
    }
  }, [success]);

  useEffect(() => {
    if (info?.amount && !amount) {
      setValue("amount", info?.amount);
    }
  }, [info?.amount]);

  useEffect(() => {
    if (fundraiserById) {
      dispatch(
        favorsActions.getFavors({
          type: fundraiserById?.type,
        })
      );
    }
  }, [dispatch, fundraiserById?.type]);

  useEffect(() => {
    return () => {
      dispatch(fundraiserActions.resetFundraiserById());
    };
  }, []);

  const handleCardPayment = async (event) => {
    setLoader(true);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        switch (error.code) {
          case "incomplete_number":
          case "invalid_number":
            setErrors((prev) => ({
              ...prev,
              cardNumber: "Please enter a valid card number.",
            }));
            break;
          case "incomplete_expiry":
          case "invalid_expiry_year_past":
          case "invalid_expiry_year":
          case "invalid_expiry_month":
            setErrors((prev) => ({
              ...prev,
              expiry: "Please enter a valid expiry date.",
            }));
            break;
          case "incomplete_cvc":
          case "invalid_cvc":
            setErrors((prev) => ({
              ...prev,
              cvc: "Please enter a valid CVV.",
            }));
            break;
          default:
            setErrors((prev) => ({
              ...prev,
              els: error.message,
            }));
        }
      } else {
        const params = {
          email: event?.email,
          donorName: event?.donorName,
          fundraiser: fundraiserById?.id,
          pmtMethod: paymentMethod?.id,
          amount: +event?.amount,
          description: event?.description || null,
        };

        if (activeItems) {
          params.favors = activeItems?.map((item) => item?.id);
        }
        dispatch(donationActions.createDonations(params));
      }
    } catch (e) {
      setErrors((prev) => ({
        ...prev,
        els: e?.data?.message,
      }));
    } finally {
      setLoader(false);
    }
  };

  const handlePaymentRequest = () => {
    const amountInCents = (+info?.amount || 0) * 100;

    try {
      if (paymentRequest?.paymentRequest) {
        paymentRequest?.paymentRequest.update({
          total: {
            label: "Demo payment",
            amount: amountInCents,
          },
        });
        paymentRequest?.paymentRequest.show();

        // Remove existing listener to avoid duplication
        paymentRequest?.paymentRequest.off("paymentmethod");

        // Define and add the event listener
        const handlePaymentMethod = async (event) => {
          try {
            const params = {
              email: watch("email"),
              donorName: watch("donorName"),
              fundraiser: fundraiserById?.id,
              pmtMethod: event?.paymentMethod?.id,
              amount: +info?.amount,
              description: watch("description") || null,
            };

            const { data } = await donationService.createDonationService(
              params
            );

            const { error, paymentIntent } = await stripe.confirmCardPayment(
              data.clientSecret,
              {
                payment_method: event?.paymentMethod?.id,
              },
              {
                handleActions: false,
              }
            );

            if (error) {
              event.complete("fail");
              return;
            }

            event.complete("success");
            if (paymentIntent.status === "requires_action") {
              stripe.confirmCardPayment(data.clientSecret);
            }
            navigate("/payment-success");
          } catch (e) {
            event.complete("fail");
          }
        };

        paymentRequest?.paymentRequest.on("paymentmethod", handlePaymentMethod);
      }
    } catch (e) {
      toast.warning(
        "You cannot update Payment Request options while the payment sheet is showing"
      );
    }
  };

  const onSubmit = async (event) => {
    if (!stripe || !elements) {
      return;
    }

    payType === "card" && (await handleCardPayment(event));

    (payType === "applePay" || payType === "googlePay") &&
      handlePaymentRequest();
  };

  const amountChangeControl = (e) => {
    if (e.target.value < 0) {
      e.target.value = 0;
    }
    setValue("amount", e.target.value);

    if (e.target.value) {
      clearErrors("amount");
    } else {
      setError("amount");
    }

    SaveParams(location?.pathname, navigate, {
      ...info,
      activeItems: [],
      amount: e.target.value,
    });
  };

  const handleItemInList = (item, location) => {
    let amountItems = 0;

    activeItems.forEach((element) => {
      amountItems += element?.price;
    });

    if (!activeItems.some((element) => element?.id === item?.id)) {
      if (amountItems + item?.price > amount) {
        toast("You can't add more items");
      } else {
        SaveParams(location?.pathname, navigate, {
          ...info,
          activeItems: [...activeItems, item],
        });
      }
    } else {
      SaveParams(location?.pathname, navigate, {
        ...info,
        activeItems: activeItems?.filter((element) => element?.id !== item?.id),
      });
    }
  };

  const handleSeeItems = () => {
    if (width < 768) {
      navigate("/favor-items", {
        state: {
          id: params?.id,
          activeItems,
          amount,
          type: fundraiserById?.type,
        },
      });
    } else {
      openModal(
        <ItemsModal
          handleItemInList={handleItemInList}
          type={fundraiserById?.type}
          amount={amount}
        />,
        {
          padding: "noPaddings",
          noShowCloseBrn: true,
        }
      );
    }
  };

  return (
    <form className="donate-page" onSubmit={handleSubmit(onSubmit)}>
      <div className="donate-page-block">
        <div className="donate-page-back-btn-box">
          <BackBtn
            handleClick={() => navigate(`/event/${fundraiserById?.id}`)}
          />
        </div>

        <div className="donate-page-wrapper">
          <div className="donate-page-amount-wrapper">
            <div>
              <p className="donate-page-card-title">Write Amount to Donate</p>
              <div className="donate-page-card-box">
                <div
                  className={`donate-page-amount-box ${
                    errors?.amount ? "donate-input-error" : ""
                  }`}
                >
                  <div>
                    <p>USD</p>
                  </div>

                  <input
                    type="number"
                    name={"amount"}
                    onWheel={(e) => {
                      e?.preventDefault();
                      e.target.blur();
                    }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "-" ||
                        e.key === "e" ||
                        e?.key === "ArrowDown" ||
                        e?.key === "ArrowUp" ||
                        e?.key === "."
                      ) {
                        e.preventDefault();
                      }
                    }}
                    {...register("amount", {
                      required: true,
                    })}
                    onChange={amountChangeControl}
                  />
                </div>
                {!!favors?.length && (
                  <div className="send-item-donation-wrapper">
                    <div className="send-item-title-button_box">
                      <p className="send-item-title">Send Item</p>
                      <button
                        className="see-all-item-donation-button"
                        type="button"
                        onClick={handleSeeItems}
                      >
                        Browse
                      </button>
                    </div>

                    <Swiper
                      className="send-item-donation-swiper"
                      slidesPerView="auto"
                      spaceBetween={10}
                    >
                      {favors?.map((item, index) => (
                        <SwiperSlide key={index} virtualIndex={index}>
                          <FavorSmallCard
                            item={item}
                            onClick={handleItemInList}
                            activeItems={activeItems}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </div>
            </div>

            <div className="donater-information-box">
              <div>
                <p className="donate-page-card-title">
                  Write Your Name or Nickname*
                </p>
                <input
                  type="text"
                  className={`donate-page-nickname ${
                    errors?.donorName ? "donate-input-error" : ""
                  }`}
                  placeholder="Name..."
                  name={"donorName"}
                  {...register("donorName", { required: true })}
                />
                <p className="donate-page-nickname-subtitle">
                  *Use a nickname instead of your real name to keep your
                  identity private.
                </p>
              </div>

              <div>
                <p className="donate-page-card-title">Email*</p>
                <input
                  type="email"
                  className={`donate-page-nickname ${
                    errors?.donorName ? "donate-input-error" : ""
                  }`}
                  placeholder="example@gmail.com"
                  name={"email"}
                  {...register("email", { required: true })}
                />
              </div>

              <div>
                <p className="donate-page-card-title">Your Message</p>
                <textarea
                  className={`donate-page-text-area ${
                    errors?.description ? "donate-input-error" : ""
                  }`}
                  placeholder="Write something you want to say..."
                  name={"description"}
                  {...register("description", { required: false })}
                />
              </div>
            </div>
            <div>
              <p className="donate-page-card-title">
                Select the Payment Method
              </p>

              <AccordionRadio
                handleChange={(item) => {
                  setPayType(item?.value);
                }}
                expanded={payType}
                list={[
                  {
                    value: "card",
                    icon: Svg.CreditCard,
                    title: "Credit card or debit card",
                    accordionDetails: payType === "card" && (
                      <div className="checkoutForm-wrapper">
                        <div className="credit-card-number">
                          <label className="checkoutForm-label" htmlFor="card">
                            Card Number
                            <CardNumberElement
                              id={"card"}
                              options={{
                                style: {
                                  base: {
                                    fontSize: "16px",
                                    color: "#152E47",

                                    "::placeholder": {
                                      color: "#aab7c4",
                                    },
                                  },
                                  invalid: {
                                    color: "red",
                                  },
                                },
                                classes: {
                                  base: "checkoutForm-input",
                                  invalid: "checkoutForm-input-error",
                                },
                                placeholder: "xxxx xxxx xxxx xxxx",
                              }}
                            />
                            <p className="card-errors">{error?.cardNumber}</p>
                          </label>
                        </div>

                        <div className="expiry-date-cvv">
                          <div className="credit-card-expiry-date">
                            <label
                              className="checkoutForm-label"
                              htmlFor="date"
                            >
                              MM/YY
                              <CardExpiryElement
                                id={"date"}
                                options={{
                                  style: {
                                    base: {
                                      fontSize: "16px",
                                      color: "#152E47",

                                      "::placeholder": {
                                        color: "#aab7c4",
                                      },
                                    },
                                    invalid: {
                                      color: "red",
                                    },
                                  },
                                  classes: {
                                    base: "checkoutForm-input",
                                    invalid: "checkoutForm-input-error",
                                  },
                                  placeholder: "MM / YY",
                                }}
                              />
                            </label>
                            <p className="card-errors">{error?.expiry}</p>
                          </div>

                          <div className="credit-card-cvv">
                            <label className="checkoutForm-label" htmlFor="cvv">
                              CVV
                              <CardCvcElement
                                id="cvv"
                                options={{
                                  style: {
                                    base: {
                                      fontSize: "16px",
                                      color: "#152E47",

                                      "::placeholder": {
                                        color: "#aab7c4",
                                      },
                                    },
                                    invalid: {
                                      color: "red",
                                    },
                                  },
                                  classes: {
                                    base: "checkoutForm-input",
                                    invalid: "checkoutForm-input-error",
                                  },
                                  placeholder: "XXX",
                                }}
                              />
                            </label>

                            <p className="card-errors">{error?.cvc}</p>
                          </div>
                        </div>
                        <p className="card-errors">{error?.els}</p>
                      </div>
                    ),
                  },

                  {
                    value: "googlePay",
                    icon: Svg.GooglePaySvg,
                    title: "Google Pay",
                    disabled: !paymentRequest?.result?.googlePay,
                  },

                  {
                    value: "applePay",
                    icon: Svg.ApplePaySvg,
                    title: "Apple Pay",
                    disabled: !paymentRequest?.result?.applePay,
                  },
                ]}
              />
            </div>
          </div>

          <div className="donate-page-contribution-wrapper">
            <p className="contribution-summary-title">Contribution Summary</p>
            <div className="donate-summary-box">
              <div className="donate-summary-head">
                <img
                  src={
                    fundraiserById?.images?.length
                      ? fundraiserById?.images[
                          fundraiserById?.mainImage
                            ? fundraiserById?.mainImage
                            : 0
                        ]?.url
                      : Images.needCardFrameWhite
                  }
                  alt="frame"
                />
                <p>{fundraiserById?.name}</p>
              </div>
              <div className="donate-summary-body">
                <div
                  className={`donate-summary-info-box`}
                  style={{ paddingTop: "12px" }}
                >
                  <p>Your Donation</p>
                  <PriceRow info={+amount} />
                </div>

                {activeItems?.length > 0 && (
                  <div
                    className={`donate-summary-info-box`}
                    style={{ paddingTop: "12px" }}
                  >
                    <p>items</p>
                    <ItemsAndTooltip items={activeItems} />
                  </div>
                )}
                <div
                  className="donate-summary-info-box"
                  style={{ paddingBottom: "12px", marginTop: "4px" }}
                >
                  <p>Tax</p>
                  <PriceRow info={0} />
                </div>
                <div className="donate-summary-bold-info-box donate-summary-bold-info_total">
                  <p>Total</p>
                  <PriceRow info={+amount} />
                </div>
              </div>
            </div>

            <div className="terms-and-conditions">
              <Checkbox
                style={{ marginRight: 8 }}
                checked={agree}
                onClick={() => setAgree(!agree)}
              />
              <p>
                By clicking the Donate button, you agree to the
                <a
                  style={{ marginLeft: 4 }}
                  href={"/terms"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Terms & Conditions &
                </a>
                <a href={"/privacy-policy"} target={"_blank"} rel="noreferrer">
                  {" "}
                  the Privacy Policy.{" "}
                </a>
              </p>
            </div>

            {payType === "card" ? (
              <ButtonMain
                type="submit"
                text="Donate"
                className="generate-report-button"
                disabled={!agree ? true : loader || loadCreation}
                load={loader || loadCreation}
                startIcon={<Svg.HeartSvg />}
              />
            ) : (
              <GoogleApplePay
                disabled={!agree}
                stripe={stripe}
                elements={elements}
                paymentRequest={paymentRequest?.paymentRequest}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </form>
  );
};
