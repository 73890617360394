import { Svg } from "assets";

export const LoggedUserLinks = [
  {
    title: "My Profile",
    icon: <Svg.Profile className="logged-link-svg_fill" />,
    link: "/settings",
  },
  {
    title: "Events",
    icon: <Svg.FundraisersLink className="logged-link-svg_fill" />,
    link: "/events",
  },
  {
    title: "Transfers",
    icon: <Svg.Transfers className="logged-link-svg_fill" />,
    link: "/transfers",
  },
];

export const LoggedAdminLinks = [
  {
    title: "My Profile",
    icon: <Svg.Profile className="logged-link-svg_fill" />,
    link: "/settings",
  },
  {
    title: "Users",
    icon: <Svg.ProfileLink className="logged-link-svg_stroke" />,
    link: "/users",
  },
  {
    title: "Events",
    icon: <Svg.FundraisersLink className="logged-link-svg_fill" />,
    link: "/events",
  },
  {
    title: "Charities",
    icon: <Svg.CharitiesLink className="logged-link-svg_fill" />,
    link: "/charities",
  },

  {
    title: "Items",
    icon: <Svg.FavorsItemsLink className="logged-link-svg_fill" />,
    link: "/favors",
  },
  {
    title: "Transfers",
    icon: <Svg.Transfers className="logged-link-svg_fill" />,
    link: "/transfers",
  },
];
