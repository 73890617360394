import React, { forwardRef, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { InputAdornment, TextField } from "@mui/material";
import { Svg } from "assets";
import moment from "moment";

const getNestedError = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

export const InputMain = forwardRef(
  (
    {
      className,
      label,
      labelHeader,
      placeholder,
      rules,
      control,
      name,
      type = "text",
      maxLength,
      startAdornment,
      endAdornment,
      onClickIconStart,
      onClickIconEnd,
      disabled,
      min,
      max,
      onFocus,
      onBlur,
      ...anotherProps
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const inputRef = useRef(null);

    const typePassword = showPassword ? "text" : "password";
    const EndIcon = showPassword ? Svg.EyeSvg : Svg.EyeSlashSvg;

    const onLength = (e) => {
      if (maxLength) {
        return (e.target.value = e.target.value.slice(0, maxLength));
      }
    };

    return (
      <div
        className={`input-main-wrapper input-custom-wrapper ${
          className ? className : ""
        }`}
        ref={ref}
      >
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field, formState: { errors } }) => {
            const error = getNestedError(errors, name);

            const hasError = !!error?.message;

            if (hasError && inputRef.current) {
              // inputRef.current.focus();
              inputRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }

            return (
              <>
                {labelHeader && (
                  <p
                    className={`input-main-label-header ${
                      error?.message?.message ? "errorLabel" : ""
                    }`}
                  >
                    {labelHeader}
                    {rules?.required && (
                      <span className="input-main-label-header-required">
                        *
                      </span>
                    )}
                  </p>
                )}
                <TextField
                  onInput={(e) => onLength(e)}
                  inputRef={(el) => {
                    field.ref(el);
                    inputRef.current = el;
                  }}
                  disabled={disabled}
                  {...anotherProps}
                  {...field}
                  onWheel={(e) => {
                    if (type === "number") {
                      e?.preventDefault();
                      e.target.blur();
                    }
                  }}
                  onChange={(event) => {
                    if (+event.target.value < 0) {
                      event.target.value = 0;
                    }
                    field?.onChange(event);
                  }}
                  autoComplete="off"
                  error={hasError}
                  type={showPassword ? typePassword : type}
                  value={field.value}
                  placeholder={placeholder}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  label={label}
                  inputProps={{
                    min: min ? moment.utc(min).format("YYYY-MM-DD") : "",
                    max: max ? max : "",
                    maxLength: maxLength ? maxLength : "",
                  }}
                  InputProps={{
                    startAdornment: startAdornment && (
                      <InputAdornment
                        onClick={onClickIconStart}
                        className={`${
                          error?.message?.message ? "error-svg" : ""
                        }`}
                        position="start"
                      >
                        {startAdornment}
                      </InputAdornment>
                    ),
                    endAdornment:
                      (endAdornment && (
                        <InputAdornment
                          onClick={onClickIconEnd}
                          position="end"
                          className={`${
                            error?.message?.message ? "error-svg" : ""
                          }`}
                        >
                          {endAdornment}
                        </InputAdornment>
                      )) ||
                      (type === "password" && (
                        <InputAdornment
                          onClick={(e) => {
                            onClickIconEnd && onClickIconEnd(e);
                            setShowPassword((prev) => !prev);
                          }}
                          position="end"
                          className={`${
                            error?.message?.message ? "error-svg-password" : ""
                          }`}
                        >
                          <EndIcon />
                        </InputAdornment>
                      )),
                  }}
                />
                <p className="error-message">{error?.message}</p>
              </>
            );
          }}
        />
      </div>
    );
  }
);

InputMain.displayName = "InputMain";
