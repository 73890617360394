import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Radio,
} from "@mui/material";

export const AccordionRadio = ({ list, handleChange, expanded }) => {
  const toggleAccordion = (index, item) => {
    handleChange && handleChange(item, index);
  };

  return (
    list?.length > 0 && (
      <div className="accordion-radio-wrapper">
        {list.map(
          (item, index) =>
            item && (
              <Accordion
                className="accordion-radio-box"
                key={index}
                expanded={expanded === item?.value}
                disabled={item?.disabled}
              >
                <AccordionSummary
                  onClick={() => toggleAccordion(index + 1, item)}
                  className={`accordion-radio-summary-wrapper ${
                    !item?.accordionDetails
                      ? "accordion-radio-summary-no-expanded"
                      : ""
                  }
                   ${item?.disabled ? "accordion-radio-summary-disabled" : ""}
                  `}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div className="accordion-radio-summary-box">
                    <div className="accordion-radio-summary-icon_title">
                      {item?.icon && <item.icon />}
                      <p className="accordion-radio-title">{item?.title}</p>
                    </div>

                    <Radio
                      checked={expanded === item?.value}
                      onChange={() => toggleAccordion(index + 1, item)}
                      value={item?.value}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                      disabled={item?.disabled}
                    />
                  </div>
                </AccordionSummary>

                {item?.accordionDetails && (
                  <AccordionDetails className="accordion-radio-details-wrapper">
                    {item?.accordionDetails}
                  </AccordionDetails>
                )}
              </Accordion>
            )
        )}
      </div>
    )
  );
};
