import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { charityActions } from "store";
import { FindLoad } from "utils";
import { BackBtn, PageLoader, SearchInput, SimpleTabs } from "components";
import {
  CHARITY_BY_ID,
  CharityFundraisers,
  CharityDetailsScreen,
  charityDetailTabs,
  charityTabEnum,
} from "./fragments";
import { CharityBankAccount } from "./fragments/charityBankAccount";

export const CharityDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const loader = FindLoad(CHARITY_BY_ID);
  const location = useLocation();
  const info = location?.state;

  useEffect(() => {
    dispatch(charityActions.getCharityById(params?.id));
  }, [dispatch]);

  if (loader) {
    return <PageLoader />;
  }

  return (
    <div className="fundraiser-details-page">
      <BackBtn
        className="event-details-back-btn"
        handleClick={() => navigate("/charities")}
      />

      <div className="charity-details-tab">
        <SimpleTabs
          list={charityDetailTabs}
          defaultTab={charityTabEnum.DETAILS}
        />

        {info?.tab === charityTabEnum.FUNDRAISES && (
          <div className="search-box-tablet">
            <SearchInput placeholder={"Search by name..."} searchKey={"name"} />
          </div>
        )}
      </div>

      {info?.tab === charityTabEnum.FUNDRAISES && (
        <div className="search-box-mobile">
          <SearchInput placeholder={"Search by name..."} searchKey={"name"} />
        </div>
      )}

      {(!info?.tab || info?.tab === charityTabEnum.DETAILS) && (
        <CharityDetailsScreen />
      )}
      {info?.tab === charityTabEnum.BANK_ACCOUNT && <CharityBankAccount />}
      {info?.tab === charityTabEnum.FUNDRAISES && <CharityFundraisers />}
    </div>
  );
};
