import React from "react";
import { Tooltip } from "@mui/material";
import { useModal } from "utils";
import { FavorsImagesSlide } from "./favorsImagesSlide";

export const ItemsAndTooltip = ({ items, tooltipTitle }) => {
  const { openModal } = useModal();

  const openSlider = () => {
    openModal(<FavorsImagesSlide items={items} />, {
      padding: "noPaddings",
      noShowCloseBrn: true,
      className: "favors-images-slide-modal",
    });
  };

  return (
    <div className="items-and-tooltip-wrapper">
      <div className="items-and-tooltip-box" onClick={openSlider}>
        {items?.slice(0, 3)?.map((item, index) => (
          <div
            className={`items-and-tooltip-image ${
              index ? "items-and-tooltip-image-margin" : ""
            }`}
            key={index}
          >
            <img src={item?.image?.url} alt="item" />
          </div>
        ))}

        {items?.length > 3 && (
          <Tooltip
            title={tooltipTitle || "View All Items images"}
            className={"favor-items-images-and-tooltip-wrapper"}
            classes={{ tooltip: "favor-items-images-and-tooltip" }}
            arrow={true}
          >
            <p className="favor-items-images-and-tooltip-text">
              +{items?.length - 3}
            </p>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
